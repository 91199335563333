<template>
  <el-select v-model="difficultId" @change="handleChange"  placeholder="请选择" style="width:100%">
    <el-option
        v-for="difficult in difficults"
        :key="difficult.id"
        :label="difficult.name"
        :value="difficult.id">
    </el-option>
  </el-select>
</template>

<script>
import {UrlEnum} from "../../public/js/common-vue";

export default {
  name: "SelectDifficult",
  data(){
    return{
      difficultId:'',
      difficults:[]
    }
  },
  methods:{
    initData(allItems,checkedVal){
      this.difficultId = checkedVal;
      if(allItems == null){
        this.$http({
          method: "get",
          url: UrlEnum.QUESTION_DIFFICULT,
        }).then((res) => {
          this.difficults = res.data;
        }).catch((res) => {
          if(res.response != null){
            const data = res.response.data;
            this.showMsgError(data.message);
          }
        });
      }
      else{
        this.difficults = allItems;
      }
    },
    handleChange:function (){
      this.$emit('parentEvent',this.difficultId)
    }
  },
}
</script>

<style scoped>

</style>
