<template>
  <div>
  <el-form-item v-for="(item,i) in options" :label="item.label">
    <el-input type="text" class="txt-option" v-model="item.content" placeholder="请输入"></el-input>
    <el-radio v-show="optionType=='radio'" @change="handleRadioClick(item.index)" v-model="item.right" :label="true">正确</el-radio>
    <el-checkbox v-show="optionType=='checkbox'" v-model="item.right" :label="true">正确</el-checkbox>
  </el-form-item>
    <div class="el-form-item" v-show="isShowBtn">
      <label class="el-form-item__label"  @click="addOption(options.length,0,'',false)" style="width: 100px;cursor: pointer; color: rgb(64, 175, 254);">增加选项</label>
      <div class="el-form-item__content" style="margin-left: 100px;"><label class="el-form-item__label"  @click="cutOption()"  style="width: 100px;cursor: pointer; color: rgb(64, 175, 254);">减少选项</label></div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      name: "QuestionOption",
      options:[],
      optionType : '',
      isShowBtn:false
    }
  },
  methods:{
    handleRadioClick(index){
      for(let i=0;i<this.options.length;i++){
        this.options[i].right=false;
        if(this.options[i].index == index){
          this.options[i].right = true;
        }
      }
    },
    addOption:function (index,id,content,isRight){
      const i = this.options.length;
      this.options.push({index:index,id:id,label:'选项' + (i+1),content:content,right:isRight});
    },
    cutOption:function (){
      if(this.options.length == 0){
        return;
      }
      this.options.splice(this.options.length-1,1);
    },
    initData(optionType,options,isShowBtn){
      this.optionType = optionType;
      this.isShowBtn=isShowBtn;
      if(options == null){
        for(let i=0;i<4;i++){
          if(i==0){
            this.addOption(i,0,'',true);
          }
          else {
            this.addOption(i,0,'',false);
          }
        }
      }
      else{
        for(let i = 0; i < options.length; i++){
          let option = options[i];
          this.addOption(i,option.id,option.content,option.right);
        }
      }
    },
    changeType(optionType){
      this.optionType = optionType;
    },
    getOptions:function (){
      let arr = [];
      if(this.options != null){
        for(let i = 0; i < this.options.length; i++){
          if(this.options[i].content.trim() != ""){
            arr.push(this.options[i])
          }
        }
      }
      return arr;
    },
  },
  mounted() {}
}
</script>

<style scoped>
.el-input {
  width: calc(100% - 82px) !important;
  margin-right: 10px;
}
.el-radio{
  margin-right: 0px !important;
}
</style>
