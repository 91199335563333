<template>
  <el-form-item label="专业" :required="true" v-show="this.isShow">
    <el-checkbox  v-for="major in majors" v-model="majorIds" :label="major.id">
      {{major.name}}
    </el-checkbox >
  </el-form-item>
 </template>

<script>
import {UrlEnum,getLocalProjectId,ProjectEnum} from "../../public/js/common-vue";

export default {
  name: "CheckboxMajor",
  data(){
    return{
      majors:[],
      majorIds:[],
      isShow : getLocalProjectId() == ProjectEnum.FA_SHUO ? true : false
    }
  },
  methods:{
    initData(checkedMajors){
      if(checkedMajors != null && checkedMajors.length>0){
        for(let i=0;i<checkedMajors.length;i++){
          this.majorIds.push(checkedMajors[i].id);
        }
      }

      this.$http({
        method: "get",
        url: UrlEnum.MAJORS+'?page=1&size=100',
      }).then((res) => {
        this.majors = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getCheckedIds(){
      return this.majorIds;
    }
  }
}
</script>

<style scoped>

</style>
