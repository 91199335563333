<template>
  <el-form-item label="知识点" :required="true">
    <select-tree-multiple ref="selectTree"></select-tree-multiple>
  </el-form-item>
</template>

<script>
import {request, UrlEnum} from "../../public/js/common-vue";
import {SESSON_KEY_QUESTION_SELKNOWLEDGES} from "../../public/js/sesson-key-vue";
import {uniqueList} from "../../public/js/function-vue";
import SelectTreeMultiple from "@/components/SelectTreeMultiple";
export default {
  name: "SelectTreeKnowledge",
  components: {SelectTreeMultiple},
  data(){
    return{}
  },
  methods:{
    //获取session（区分不同的科目）
    getSessionKnowledges() {
      let session = sessionStorage.getItem(SESSON_KEY_QUESTION_SELKNOWLEDGES + request('subjectId'));
      if(session != null){
        let nodes = JSON.parse(session);
        return nodes;
      }
      return null;
    },
    //保存session(区分不同的科目)
    setSessionKnowledges() {
      const nodes = this.$refs.selectTree.getCheckedNodes();
      for(let i=0;i<nodes.length;i++){
        nodes[i].name = nodes[i].title
      }
      const subjectId = request("subjectId");
      sessionStorage.setItem(SESSON_KEY_QUESTION_SELKNOWLEDGES+subjectId,JSON.stringify(nodes))
    },
    getCheckedIds(){
      //先保存记录
      this.setSessionKnowledges();
      return this.$refs.selectTree.getCheckedIds();
    },
    hideTree(){
      this.$refs.selectTree.hideTree();
    },
    changeItems(items){
      if(items != null){
        for(let i=0;i<items.length;i++) {
          items[i].title = items[i].name;
        }
      }
    },
    initData(allItems,checkedItems,subjectId){
      this.changeItems(checkedItems);
      if(allItems == null){
        this.$http({
          method: "get",
          url:UrlEnum.KNOWLEDGE_TREE+"?subjectId="+subjectId
        }).then((res) => {
          allItems = res.data.list;
          console.log(UrlEnum.KNOWLEDGE_TREE+"?subjectId="+subjectId)
          console.log(allItems);
          this.$refs.selectTree.initData(allItems,checkedItems);
        }).catch((res) => {
          if(res.response != null){
            const data = res.response.data;
            this.showMsgError(data.message);
          }
        });
      }
      else{
        this.$refs.selectTree.initData(allItems,checkedItems);
      }
    }
  }
}
</script>
