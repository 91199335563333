<template>
<select-tree-multiple ref="selectTree"></select-tree-multiple>
</template>

<script>
import {getLocalProjectId, UrlEnum} from "../../public/js/common-vue";
import SelectTreeMultiple from "@/components/SelectTreeMultiple";
export default {
  name: "LawSelectTree",
  components: {SelectTreeMultiple},
  data(){
    return{}
  },
  methods:{
    getCheckedIds(){
      return this.$refs.selectTree.getCheckedIds();
    },
    hideTree(){
      this.$refs.selectTree.hideTree();
    },
    changeItems(items){
      if(items != null){
        for(let i=0;i<items.length;i++) {
          items[i].title = items[i].name;
        }
      }
    },
    initData(allItems, checkedItems){
      this.changeItems(checkedItems);
      if(allItems == null){
        this.$http({
          method: "get",
          url: UrlEnum.LAW_TREE+"?projectId="+getLocalProjectId(),
        }).then((res) => {
          allItems = res.data.list;
          this.$refs.selectTree.initData(allItems,checkedItems);
        }).catch((res) => {
          if(res.response != null){
            const data = res.response.data;
            this.showMsgError(data.message);
          }
        });
      }
      else{
        this.$refs.selectTree.initData(allItems,checkedItems);
      }
    }
  }
}
</script>

<style scoped>

</style>
